<template>
  <b-card-actions
      ref="card-actions"
      class="actions-card"
      @refresh="refreshStop('card-actions')"
  >
    <b-row class="mt-50">
      <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
        <b-col cols="12">
          <h4 class="text-white d-inline-block card-title">Fiş Listesi</h4>
          <b-button
              v-if="false"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-primary"
              class="text-white float-right bg-primary bg-lighten-2 card-right-button"
              to="/yeniAlis"
              :style="{backgroundColor:$store.getters.COLORS_GET.cardTitleButton+'!important',border:'none'}"
          >
            Fatura OLuştur
          </b-button>
        </b-col>
      </div>
    </b-row>
    <b-row class="mt-5">
      <b-col lg="6" md="12" sm="12" cols="12" clas="d-flex justify-content-start">
        <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
          <b-input-group
              size="sm"
              prepend="Gösterilecek kayıt sayısı"
              class="unPrint per-page"
          >
            <b-form-select
                v-model="perPage"
                :options="pageOptions"
                size="sm"
                @change="perPageChange($event)"
            >
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col lg="6" md="12" sm="12" cols="12" class="d-flex justify-content-end">
        <b-input-group size="sm" class="search-bar" :style="{width: width < 1300 ? '60%!important':''}">
          <b-input-group-prepend>
            <b-button
                variant="outline-primary"
                size="sm"
                disabled
                class="search-input mb-0"
            >
              Ara
            </b-button>
          </b-input-group-prepend>
          <b-form-input id="filterInput" v-model="filter" type="search"/>
        </b-input-group>
      </b-col>
    </b-row>
    <hr/>
    <b-table
        striped
        small
        hover
        responsive
        class="position-relative cari-group-table mt-1"
        :items="fisListesi"
        :fields="fields"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter-included-fields="filterOn"
        @filtered="FirstOnFiltered"
        @row-clicked="goDetay"
    >
      <template #cell(fisID)="data">
        <b-badge variant="primary" class="align-top">
          #{{ data.item.fisID }}
        </b-badge>
      </template>
      <template #cell(firmaUnvan)="data">
        <strong class="text-dark">
          {{ data.item.firmaUnvan }}
        </strong>
      </template>
      <template #cell(fisNotu)="data">
        <strong class="text-dark fatura-notu">
          {{ data.item.fisNotu }}
        </strong>
      </template>
      <template #cell(araToplam)="data">
        <strong class="text-nowrap">
          {{ data.item.araToplam }}
          <font-awesome-icon icon="turkish-lira-sign"/>
        </strong>
      </template>
      <template #cell(toplamVergi)="data">
        <strong class="text-nowrap">
          {{ data.item.toplamVergi }}
          <font-awesome-icon icon="turkish-lira-sign"/>
        </strong>
      </template>
      <template #cell(toplamTutar)="data">
        <strong class="text-nowrap">
          {{ data.item.toplamTutar }}
          <font-awesome-icon icon="turkish-lira-sign"/>
        </strong>
      </template>
      <template #cell(kayitTarih)="data">
        <b-badge
            id="bakiye-badge"
            variant="light-primary"
            size="sm"
        >
          {{ data.item.kayitTarih.slice(0, 16) }}
        </b-badge>
      </template>
    </b-table>
    <hr/>
    <b-row class="d-flex align-items-center mt-1">
      <b-col cols="6">
        <p class="text-muted">Toplam {{ totalRows }} Kayıt bulundu</p>
      </b-col>
      <b-col cols="6">
        <b-pagination
            v-model="currentPage"
            @change="pageChange($event)"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0 float-right"
        />
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import {
  BFormSelect,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import store from '@/store'
import { useWindowSize } from '@vueuse/core'

export default {
  name: 'FisListesi',
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BFormSelect,
    BPagination,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BTable,
    BBadge,
    BRow,
    BCol,
    BInputGroupPrepend,
    BCardActions,
  },
  data() {
    return {
      fields: [
        {
          key: 'fisID',
          label: 'Fiş ID',
          sortable: true,
          thStyle: {
            width: '5%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '5%' },
        },
        {
          key: 'firmaUnvan',
          label: 'Cari Kart',
          sortable: true,
          thStyle: {
            width: '25%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '25%' },
        },
        {
          key: 'fisNotu',
          label: 'Fiş Notu',
          sortable: true,
          thStyle: {
            width: '20%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'araToplam',
          label: 'Tutar',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
            width: '20%',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'toplamVergi',
          label: 'Toplam Vergi',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
            width: '20%',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'toplamTutar',
          label: 'Toplam Tutar',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
            width: '20%',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'kayitTarih',
          label: 'Tarih',
          sortable: true,
          thStyle: {
            width: '15%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterOn: [],
      filter: null,
      perPage: 20,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      toplamKayit: 1,
      totalRows: 1,
      currentPage: 1,
    }
  },
  computed: {
    fisListesi() {
      return Object.values(store.getters.FISLIST_GET)
    },
  },
  watch: {
    filter(newVal) {
      this.$refs['card-actions'].showLoading = true
      this.$store
          .dispatch('fisListesiSearch', {
            uzunluk: this.perPage,
            baslangic: 0,
            searchKey: newVal,
          })
          .then(res => {
            this.$refs['card-actions'].showLoading = false
            this.totalRows = res.toplamKayit
            this.toplamKayit = res.data?.length
          })
    },
  },
  created() {
    store.dispatch('fisListesiSearch', {
      uzunluk: this.perPage,
      baslangic: 0,
      searcKey: this.filter,
    })
        .then(res => {
          this.totalRows = res.toplamKayit
          this.toplamKayit = res.data.length
        })
  },
  methods: {
    goDetay(item) {
      router.push({
        name: 'fisDetay',
        params: { id: item.fisID },
      })
    },
    pageChange(newVal) {
      const veri = newVal * this.perPage - this.perPage
      this.$refs['card-actions'].showLoading = true
      this.$store
          .dispatch('fisListesiSearch', {
            uzunluk: this.perPage,
            baslangic: veri,
            searchKey: this.filter,
          })
          .then(res => {
            this.$refs['card-actions'].showLoading = false
            this.toplamKayit = res.data.length
            this.totalRows = res.toplamKayit
          })
    },
    perPageChange(newVal) {
      const veri = this.currentPage * newVal - newVal
      this.$refs['card-actions'].showLoading = true
      this.$store
          .dispatch('fisListesiSearch', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.filter,
          })
          .then(res => {
            this.$refs['card-actions'].showLoading = false
            this.toplamKayit = res.data.length
            this.totalRows = res.toplamKayit
          })
    },
    FirstOnFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'fatura-listesi'].showLoading = false
      }, 1000)
    },
  },
  beforeDestroy() {
    store.commit({
      type: 'FATURA_LIST_ACTION',
      stateTur: 'remove',
    })
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>
<style scoped>
.card-right-button {
  transform: translateY(7%) !important;
}

.card-title {
  transform: translateY(45%) !important;
}

.search-bar {
  width: 30% !important;
}

.search-input {
  border-color: #c3baba !important;
}

.fatura-turu {
  transform: translateY(50%) !important;
}

.col-8, .col-4 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

@media screen and (max-width: 1140px) {
  .fatura-unvan-col-2 {
    transform: translateX(2%);
  }
}

.lira-color {
  color: #e83e8c !important;
}

@media screen and (max-width: 3000px) {
  .per-page {
    width: 30% !important;
  }
}

@media screen and (max-width: 2000px) {
  .per-page {
    width: 35% !important;
  }
}

@media screen and (max-width: 1800px) {
  .per-page {
    width: 40% !important;
  }
}

@media screen and (max-width: 1600px) {
  .per-page {
    width: 50% !important;
  }
}

@media screen and (max-width: 1200px) {
  .per-page {
    width: 50% !important;
  }
}

@media screen and (max-width: 1100px) {
  .per-page {
    width: 55% !important;
  }
}

@media screen and (max-width: 1000px) {
  .per-page {
    width: 65% !important;
  }
}

@media screen and (max-width: 900px) {
  .per-page {
    width: 75% !important;
  }
}

@media screen and (max-width: 800px) {
  .per-page {
    width: 90% !important;
  }
}
</style>
